.wrapper {
	height: 100vh;
	padding-top: 3rem;

	&-practices {
		background: url('../../assets/images/BestPractices/top-decor.svg') no-repeat top center;
	}
	&-projects {
		background: url('../../assets/images/BestProjects/top-decor.svg') no-repeat top center;
	}
}

.qr-img {
	max-width: 150px;
}
.logo-img {
	width:auto;
	height:calc(223px - 2*1rem);

	@screen xl {
		margin: auto;
	}
}


.practice-item {
	border-radius: 1rem;
	border: 2px solid #2E2E2E;
	padding: 1.5rem;
	color: #2E2E2E;
	font-family: Gilroy;
	font-size: 1.2rem;
	font-style: normal;
	font-weight: 700;
	line-height: 131.3%;
	width: 100%;
	margin-bottom: 1.5rem;
	margin-left: auto;
	margin-right: auto;
	text-align: center;

	@screen 2xl {
		width: fit-content;
		font-size: 1.5rem;
		padding: 2rem;
		margin-bottom: 3rem;
	}

	&--green {
		background-color: #00A664;
		color: #fff;
		border: 2px solid #fff;
		animation: scale-animation .5s ease-in;
	}

	&--orange {
		background-color: #F58220;
		color: #fff;
		border: 2px solid #fff;
		animation: scale-animation .5s ease-in;
	}

	&--pink {
		background-color: #EE2E62;
		color: #fff;
		border: 2px solid #fff;
		animation: scale-animation .5s ease-in;
	}

	&--blue {
		background-color: #005BAA;
		color: #fff;
		border: 2px solid #fff;
		animation: scale-animation .5s ease-in;
	}

	&--lightblue {
		background-color: #27BDBE;
		color: #fff;
		border: 2px solid #fff;
		animation: scale-animation .5s ease-in;
	}
}

.loader-img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(-45deg);
	width: 500px;
}

.round-text {
	color: #2E2E2E;
	text-align: center;
	font-family: Gilroy;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 700;
	line-height: 131.3%;
	position: absolute;
	top: 60%;
	left: 50%;
	transform: translate(-50%, 0);

	span {
		color: #27BDBE;
		font-family: Gilroy;
		font-size: 1.25rem;
		font-style: normal;
		font-weight: 700;
		line-height: 131.3%;
	}
}

.practice-item-mr-0 {
	margin-right: 0;

	@screen xl {
		margin-right: -1rem;
	}
}
.practice-item-mr-1 {
	margin-right: 4rem;

	@screen xl {
		max-width: 530px;
		position: relative;
		right: 4rem;
	}
	@screen 2xl {
		margin-right: 0;
	}
}

.practice-item-mr-2 {
	position: relative;
	right: 0;

	@screen xl {
		max-width: 500px;
			right: 6rem;
		}

	@screen 2xl {
		right: 3rem;
	}
}

.practice-item-mr-3 {
	position: relative;
	right: 0;

	@screen xl {
		max-width: 445px;
		right: 6rem;
	}

	@screen 2xl {
		right: 0rem;
	}
}


.practice-item-ml-0 {
	margin-left: 0;

	@screen xl {
		margin-left: 0;
	}
}
.practice-item-ml-1 {
	position: relative;
	left: 0;

	@screen xl {
		left: 4rem;
	}

	@screen 2xl {
		left: -2rem;
	}
	@screen 3xl {
		left: -6rem;
	}
}

.practice-item-ml-2 {
	position: relative;
	left: 0rem;

	@screen xl {
		max-width: 436px;
		left: 6rem;
	}

	@screen 2xl {
		left: 2rem;
	}

	@screen 3xl {
		left: -1rem;
	}

}

.practice-item-ml-3 {
	position: relative;
	left: 0;

	@screen xl {
		max-width: 436px;
		left: 5rem;
	}

	@screen 2xl {
		left: -2rem;
	}
}

.practice-title {
	color: #2D2D2D;
	font-family: Gilroy;
	font-size: 1.4rem;
	font-style: normal;
	font-weight: 600;
	line-height: 131.3%;
	margin-bottom: 1.5rem;
	text-align: center;
	min-height: 71px;

	// margin-bottom: 0;

	@screen lg {

		min-height: 6.188rem;
		font-size: 2.125rem;
		margin-bottom: 1.5rem;

	}
	@screen xl {

		min-height: 8.188rem;
		font-size: 3.125rem;
		margin-bottom: 1.5rem;

	}
}

.list-wrap {
	min-height: 65px;

	@screen lg {
		min-height: 78px;
		margin-bottom: 2rem;

	}
}

.col-log-qr-style {
	background: #F6F6F6;
	padding: 1rem;
}

.practice-tab {
	max-width: 12.5rem;
	color: #2D2D2D;
	text-align: center;
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1;

	@screen lg {
		font-size: 1.5rem;
		line-height: 1.875rem;
	}

	&--active {
		color: #2FADB3;
		text-align: center;
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 1;
		position: relative;

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 2px;
			background: #70C1BB;
			position: absolute;
			left: 0;
			right: 0;
			bottom: -5px;
		}

		@screen lg {
			font-size: 1.5rem;
			line-height: 1.875rem;

			&::before{
				height: 7px;
				bottom: -15px;
			}

		}
	}
}

.current-practice-title {
	color: #000;
	font-family: Rubik;
	font-size: 1.3rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;
	margin-bottom: 1.5rem;

	@screen lg {

		font-size: 1.875rem;
		line-height: 2.25rem;
		min-height: 80px;
		margin-bottom: 1.5rem;
	}
}

.current-practice-text {
	color: #000;
	font-family: Rubik;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 2.5rem;

	@screen lg {

		font-size: 1.375rem;
		line-height: normal;
	}

	span {
		color: #77797A;
		font-family: Rubik;
		font-size: 1.375rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
}

.current-practice-btn {
	display: inline-flex;
	padding: 1rem 2rem;
	justify-content: center;
	align-items: center;
	border-radius: 1rem;
	border: 1px solid #FFF;
	background: #2A7ED3;
	color: #FFF;
	font-family: Gilroy;
	font-size: 1rem;
	font-style: normal;
	font-weight: 600;
	line-height: 131.3%;
		text-align: center;

	@screen lg {
		display:none;
	}
}

.current-practice-place {
	color: #77797A;
	text-align: center;
	font-family: Rubik;
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.875rem;
}
.current-practice-qr-txt {
	color: #77797A;
	text-align: center;
	font-family: Rubik;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.875rem;
}

.min-width-saint-petersburg {
	min-width:104px;
	@screen lg {
		min-width: 204px;
	}
}

.min-width-yamal-nenets {min-width:215px;}
.min-width-crimea {
	min-width:115px;
	@screen lg {
		min-width: 215px;
	}
}

.min-width-yugra {
	min-width:200px;
	@screen lg {
		min-width: 315px;
	}
}

.min-width-chuvashia {
	min-width:80px;
	@screen lg {
		min-width: 315px;
	}
}

.min-width-krasnoyarsk {
	min-width:135px;
	@screen lg {
		min-width: 235px;
	}
}
.min-width-magadan {
	min-width:150px;
	@screen lg {
			min-width: 250px;
		}
}

.min-width-samara {
	min-width:150px;
	@screen lg {
		min-width: 250px;
	}
}
.min-width-primorsk {
	min-width: 150px;
	@screen lg {
		min-width: 250px;
	}
}

.min-width-pskov,
.min-width-kirov,
.min-width-orel {
	min-width:130px;
	@screen lg {
			min-width: 230px;
		}
}

@keyframes scale-animation {
	0% {
	  transform: scale(1);
	}
	50% {
	  transform: scale(1.1);
	}
	100% {
	  transform: scale(1);
	}
 }

 .qr-img {
	margin:auto;

	@screen xl {
		max-width: 200px;
	}
 }
