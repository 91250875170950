.wrapper {
	height: auto;
	background: url('../../assets/images/FinanceQuizzes/mmf2023/top-decor.svg') no-repeat top center,
				url('../../assets/images/FinanceQuizzes/mmf2023/bottom-decor.svg') no-repeat bottom center;
	padding-top: 3rem;

	@screen lg {
		height:100vh;
	}
}

.wrapper-with-line {
	padding-top: 3rem;
	background: url('../../assets/images/FinanceQuizzes/mmf2023/top-decor.svg') no-repeat top center,
				url('../../assets/images/FinanceQuizzes/mmf2023/bottom-decor.svg') no-repeat bottom center;
				// url('../../assets/images/FinanceQuizzes/mmf2023/bg-line-top.svg') no-repeat center 296px,
				// url('../../assets/images/FinanceQuizzes/mmf2023/bg-line-bottom.svg') no-repeat center 722px;

	@screen 2xl {
		height: 100vh;
		background: url('../../assets/images/FinanceQuizzes/mmf2023/top-decor.svg') no-repeat top center,
					url('../../assets/images/FinanceQuizzes/mmf2023/bottom-decor.svg') no-repeat bottom center,
					url('../../assets/images/FinanceQuizzes/mmf2023/bg-line-top.svg') no-repeat center 296px,
					url('../../assets/images/FinanceQuizzes/mmf2023/bg-line-bottom.svg') no-repeat center 722px;

	}
}

.img-bg {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		background-image: none;

		@screen xl {
			top: 0;
			left: -422px;
			width: 318px;
			height: 318px;
			background-image: url('../../assets/images/FinanceQuizzes/mmf2023/left-text.svg');
		}

		@screen 2xl {
			top: calc(50% - 122px);
		}
	}
}

.change-question {
	color: #2D2D2D;
	font-family: Rubik;
	font-size: 2.5rem;
	font-weight: 600;
	line-height: 145.5%;
}


.card-inner {
	position: relative;

	span {
		position: absolute;
		z-index: 2;
		top: -1.2rem;
		left: -1.2rem;
		width: 3.5rem;
		height: 3.5rem;
		background-color: #2A7ED3;
		border-radius: 50%;
		color: #FFF;
		font-family: Gilroy;
		font-size: 1.625rem;
		font-style: normal;
		font-weight: 600;
		line-height: 131.3%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.card {
	width: 100%;
	max-width: 310px;
	height: auto;
	border-radius: 1rem;
	border: 2px solid #2FADB3;
	background: #FFF;
	box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.10);
	overflow: hidden;
	position: relative;

	@screen 3xl {
		max-width: 100%;
		height: auto;
	}

	div {
		width: 90%;
		height: 3.875rem;
		border-radius: 1rem;
		border: 1px solid #FFF;
		background: #2A7ED3;
		color: #FFF;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		bottom: 0.8rem;
		left: 50%;
		transform: translateX(-50%);
		z-index: 2;
	}
	.choose {
		font-size: 1.625rem;//26px;
	}
}

.btn {
	max-width: 100%;
	width: 100%;
	color: #fff;
	font-family: Roboto;
	display: block;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 1.375rem;
	font-weight: 600;
	border-radius: 0.63rem;
	padding: 1rem 2rem;
	margin-right: 1rem;
	margin-bottom: 1rem;

	@screen 2xl {
		max-width: 20.375rem;
		width: 20.375rem;
		height: 7.1875rem;
	}

	&-group {
		border-top: 1px solid;
		width: 100%;

		@screen lg {
			width: fit-content;
		}
	}

	&-green {
		background-color: #009757;
	}

	&-red {
		background-color: #E62259;
		// margin-right: 2rem;
	}
}

.modal-text {
	color: #000;
	text-align: center;
	font-family: Roboto;
	font-size: 1.625rem;
	font-weight: 600;
	line-height: 2.5rem;
	margin-top: 1rem;
}

.modal-link {
	color: #EF7D00;
	text-align: center;
	font-family: Circe;
	font-size: 1.25rem;
	font-weight: 800;
	line-height: 1.875rem;
	text-transform: uppercase;
}

.quizz {
	&-number {
		width: 15rem;
		height: 4rem;
		border-radius: 1rem;
		background: #2A7ED3;
		color: #FFF;
		font-family: Rubik;
		font-size: 1.5rem;
		font-weight: 600;
		line-height: 131.3%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: auto;
		margin-bottom: 3rem;

		@screen lg {
			margin: 3rem 0 3rem 0;
		}
	}

	&-text {
		text-align: center;
		color: #2D2D2D;
		font-family: Rubik;
		font-size: 1.3rem;
		font-weight: 600;
		line-height: 145.5%;
		margin-bottom: 3rem;

		@screen lg {
			font-size: 2.5rem;
			text-align:left;
			max-width: 31rem;
		}
	}
}

.img-position {
	position: absolute;
	top: 185px;
	left: calc(50% - 159px);


	@screen 2xl {
		top: 43%;
		left: 85px;
	}
}

.left-element {
	background-image: url('../../assets/images/FinanceQuizzes/mmf2023/left-element.svg');
	background-size: cover;
	background-position: top right;
	background-repeat: no-repeat;

	background-color: red;
	padding: 4rem;
	width: 50%;
}

.page-bg {
	@screen 2xl
		{
			position: relative;
			min-height: 745px;
			// background: url('../../assets/images/FinanceQuizzes/mmf2023/left-element.svg') no-repeat -105px -14px;
			background: url('../../assets/images/FinanceQuizzes/mmf2023/left-element.svg') no-repeat -160px -9px;
			background-size: contain;
		}

		@screen 3xl
		{
			min-height: 745px;
			background: url('../../assets/images/FinanceQuizzes/mmf2023/left-element.svg') no-repeat -82px -9px;
			// background: url('../../assets/images/FinanceQuizzes/mmf2023/left-element.svg') no-repeat 0px -14px;
		}
}

.page-grid-template {
	min-height: 734px;
	margin-top: 3rem;
	padding-left: 1rem;
	padding-right: 1rem;

	@apply py-4 lg:grid lg:grid-cols-12 lg:gap-x-12;

	@screen 2xl {
		padding-left: 7rem;
		padding-right: 7rem;
	}

	.page-title {
		@apply lg:row-start-2 lg:col-start-1 lg:col-span-7;
	}

	.page-image {
		// @apply lg:row-start-2 lg:col-start-8 lg:col-span-5 lg:row-span-2 flex items-center;
		@apply lg:row-start-2 lg:col-start-8 lg:col-span-5 lg:row-span-2 flex items-center;

		img {
			@screen 2xl {
				// min-height: 460px;
			}
		}
	}

	.page-body {
		@apply flex flex-col;
		@apply lg:row-start-3 lg:col-start-1 lg:col-span-7;
	}
}
