.wrapper {
	height: auto;
	background: url('../../assets/images/Game/top-decor.svg') no-repeat top center,
				url('../../assets/images/Game/bottom-decor.svg') no-repeat bottom center;
	padding-top: 3rem;
	padding-bottom: 3rem;

	@screen md {
		min-height: 100vh;
	}

	.container {
		max-width: 1000px;
	}
}

.game {
	&-hr {
		max-width: 43rem;
	}

	&-title {
		color: #2D2D2D;
		text-align: center;
		font-family: Gilroy;
		font-size: 2rem;
		font-weight: 600;
		line-height: 131.3%;
		margin: 2rem 1rem 0rem 1rem;
		position: relative;

		@screen lg {
			font-size: 3.125rem;
			margin: 6rem 0 4rem 0;
		}
	}

	&-description {
		color: #2D2D2D;
		text-align: center;
		font-family: Gilroy;
		font-size:1.3rem;
		font-weight: 600;
		line-height: 131.3%;
		margin: 2rem 1rem 0rem 1rem;

		@screen lg {
			font-size: 2.25rem;
			margin-top: 3rem;
		}
	}

	&-question {
		color: #2D2D2D;
		text-align: center;
		font-family: Gilroy;
		font-size: 2.875rem;
		font-weight: 700;
		line-height: 131.3%;
		margin-top: 3rem;
	}

	&-link {
		border-radius: 1rem;
		background: #2FADB3;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #FFF;
		font-family: Rubik;
		font-size: 1rem;
		padding: 1rem 2rem;
		font-weight: 500;
		text-transform: uppercase;
		margin-top: 3rem;

		@screen lg {
			font-size: 1.5rem;
			padding: 2rem 4rem;
		}
	}
}



.container {
	// width: 95rem;
	margin: 0 auto;
}

.question {
	&-number {
		border-radius: 1rem;
		background: #3075BB;
		width: 15rem;
		height: 4rem;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #FFF;
		font-family: Rubik;
		font-size: 1.5rem;
		font-weight: 600;
	}

	&-text {
		color: #000;
		font-family: Rubik;
		font-size: 1.3rem;
		font-weight: 600;
		line-height: normal;

		@screen lg {
			font-size: 1.875rem;
		}
	}
}

.btn-pre {
	max-width:58px;
	@screen lg {
		max-width: inherit;
	}
}

.next-btn {
	padding: 1rem 2rem;
	border-radius: 1rem;
	background: #2FADB3;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #FFF;
	font-family: Rubik;
	font-size: 1.5rem;
	font-weight: 500;
	line-height: normal;

	@screen lg {
		width: 14rem;
		height: 5.75rem;
		font-size: 1.5rem;
		padding: 0;
	}
}

.image-wrapper {
	overflow: hidden;
	border-radius: 1rem;
	position: relative;
	z-index: 2;
	background-color: #ccc;
}

.pig-phrase {
	position: absolute;
	z-index: 0;
	top: 2rem;
	left: 17rem;

}

.answer {
	&-image {
		width: auto;
		object-fit: cover;
		object-position: top;
		margin: auto;

		@screen lg {
			width: 100%;
			max-width:779px;
			height: 37rem;
		}
	}

	&-text {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		// height: 3rem;
		background: #363636;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #FFF;
		font-family: Rubik;
		font-size: 1.3rem;
		font-weight: 500;
		line-height: normal;
		padding:1rem;

		@screen lg {
			height: 6rem;
			font-size: 1.688rem;
		}
	}
}

.right-answer {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(5, 233, 136, 0.50);
}

.wrong-answer {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(211, 0, 0, 0.50);
}


.result {
	&-top-text {
		color: #2D2D2D;
		text-align: center;
		font-family: Gilroy;
		font-size: 2rem;
		font-style: normal;
		font-weight: 600;
		line-height: 131.3%;
		margin-top: 2rem;
	}

	&-score {
		color: #2D2D2D;
		text-align: center;
		font-family: Gilroy;
		font-size: 1.3rem;
		font-style: normal;
		font-weight: 600;
		line-height: 131.3%;
		margin-bottom: 3rem;

		@screen lg {
			font-size: 2.5rem;
		}
	}

	&-greeting-text {
		color: #2FADB3;
		text-align: center;
		font-family: Gilroy;
		font-size: 1.5rem;
		font-style: normal;
		font-weight: 600;
		line-height: 131.3%;
		margin-bottom: 3rem;

		@screen lg {
				font-size:2.8125rem;
			}
	}

	&-description {
		color: #2D2D2D;
		text-align: center;
		font-family: Gilroy;
		font-size: 2rem;
		font-style: normal;
		font-weight: 600;
		line-height: 131.3%;
		padding-bottom: 1rem;
	}
}

.input-email {
	border-radius: 1rem;
	border: 1px solid #000;
	background: #FFF;
	width: 100%;
	height: 5rem;
	padding: 0 1.75rem;
	position: absolute;
	top: 7rem;
	left: 0;

	&::placeholder {
		color: #D0D0D0;
		font-family: Roboto;
		font-size: 1.25rem;
		font-style: normal;
		font-weight: 300;
		line-height: 2.5rem;
	}
}

.send-btn {
	position: absolute;
	bottom: 7rem;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: center;
	align-items: center;
	row-gap: 1rem;

	&:disabled {
		opacity: 0.5;
	}
}
