@font-face
{
  font-family: "Roboto";
  src: local("Roboto"),
    // url('../src/assets/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    // url('../src/assets/fonts/Roboto-Regular.woff') format('woff'),
    url('../src/assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face
{
  font-family: "Gilroy";
  src: local("Gilroy"),
    url('../src/assets/fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/fonts/Gilroy-Regular.woff') format('woff'),
    url('../src/assets/fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Circe

@font-face {
  font-family: 'Circe'; /*400*/
  src: local('Circe-Regular'),
  url('../src/assets/fonts/Circe-Regular.woff') format('woff'),
  url('../src/assets/fonts/Circe-Regular.ttf') format('ttf'),
  url('../src/assets/fonts/Circe-Regular.eot') format('eot');
}

@font-face {
  font-family: 'Circe-Regular'; /*400*/
  src: local('Circe-Regular'),
  url('../src/assets/fonts/Circe-Regular.woff') format('woff'),
  url('../src/assets/fonts/Circe-Regular.ttf') format('ttf'),
  url('../src/assets/fonts/Circe-Regular.eot') format('eot');
}

@font-face {
  font-family: 'Circe-Bold'; /*700*/
  src: local('Circe-Bold'),
  url('../src/assets/fonts/Circe-Bold.woff') format('woff'),
  url('../src/assets/fonts/Circe-Bold.ttf') format('ttf'),
  url('../src/assets/fonts/Circe-Bold.eot') format('eot');
}

@font-face {
  font-family: 'Circe-ExtraBold'; /*800*/
  src: local('Circe-ExtraBold'),
  url('../src/assets/fonts/Circe-ExtraBold.woff') format('woff'),
  url('../src/assets/fonts/Circe-ExtraBold.ttf') format('ttf'),
  url('../src/assets/fonts/Circe-ExtraBold.eot') format('eot');
}

// GothamPro

@font-face {
  font-family: 'GothamPro'; /*400*/
  src: local('GothamPro'),
  url('../src/assets/fonts/GothamPro.woff') format('woff'),
  url('../src/assets/fonts/GothamPro.ttf') format('ttf'),
  url('../src/assets/fonts/GothamPro.eot') format('eot');
}

@font-face {
  font-family: 'GothamPro-Medium'; /*500*/
  src: local('GothamPro-Medium'),
  url('../src/assets/fonts/GothamPro-Medium.woff') format('woff'),
  url('../src/assets/fonts/GothamPro-Medium.ttf') format('ttf'),
  url('../src/assets/fonts/GothamPro-Medium.eot') format('eot');
}

@font-face {
  font-family: 'GothamPro-Bold'; /*700*/
  src: local('GothamPro-Bold'),
  url('../src/assets/fonts/GothamPro-Bold.woff') format('woff'),
  url('../src/assets/fonts/GothamPro-Bold.ttf') format('ttf'),
  url('../src/assets/fonts/GothamPro-Bold.eot') format('eot');
}

// Rubik

@font-face {
    font-family: "Rubik";
      src: url("../src/assets/fonts/RubikRegular.eot");
      src: url("../src/assets/fonts/RubikRegular.eot?#iefix")format("embedded-opentype"),
        url("../src/assets/fonts/RubikRegular.woff") format("woff"),
        url("../src/assets/fonts/RubikRegular.ttf") format("truetype");
      font-style: normal;
      font-weight: 400;
}
// @font-face {
//   font-family: 'Rubik'; /*400*/
//   src: local('Rubik'),
//       url('../src/assets/fonts/Rubik-Regular.woff') format('woff'),
//       url('../src/assets/fonts/Rubik-Regular.woff2') format('woff2'),
//       url('../src/assets/fonts/Rubik-Regular.ttf') format('ttf');
//       // url('../src/assets/fonts/Rubik-Regular.eot') format('eot');
// }

@font-face {
  font-family: 'Rubik-Light'; /*300*/
  src: local('Rubik-Light'),
  url('../src/assets/fonts/Rubik-Light.ttf') format('ttf');
}

@font-face {
  font-family: 'Rubik-Regular'; /*400*/
  src: local('Rubik-Regular'),
  url('../src/assets/fonts/Rubik-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Rubik-Medium'; /*500*/
  src: local('Rubik-Medium'),
  url('../src/assets/fonts/Rubik-Medium.ttf') format('ttf');
}

@font-face {
  font-family: 'Rubik-Bold'; /*700*/
  src: local('Rubik-Bold'),
  url('../src/assets/fonts/Rubik-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Rubik-ExtraBold'; /*800*/
  src: local('Rubik-ExtraBold'),
  url('../src/assets/fonts/Rubik-ExtraBold.ttf') format('ttf');
}
