// .wrapper {
//   width: 100%;
//   height: 100vh;
//   padding: 30px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .grid-container {
//   width: 100%;
//   max-width: 1920px;
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr;
//   gap: 30px;
// }

.btn {
	width: 120px;
	height: 90px;
	border-radius: 100px;
	border: 1px solid #000;

	img {
		width: 56px;
		height: 56px;
	}
}


.main-page-cards {
	left: 50%;
	transform: translateX(-50%);
	// top:  350px;
	top: 20rem;

	// @screen md {
	// 	top: 35%;
	// }

	// @screen 4xl {
	// 	top: 35%;
	// }
}

.main-page-card {
	// width: 478px;
	min-height: 222px;
	border-radius: 15px;
	position: relative;
	overflow: hidden;
	padding: 2rem 3rem;

	@screen 3xl {
		height: 222px;
		padding: 2rem 3rem 0;
	}

	@screen 4xl {
		display: flex;
		align-items: center;
		padding-right: 6rem;
		padding-top:0;
	}

	&-circle {
		width: 224px;
		height: 224px;
		background: #fff;
		position: absolute;
		border-radius: 50%;
		bottom: -112px;
		right: -112px;
	}
}

.card-text {
	color: #FFF;
	font-family: Gilroy;
	font-size: 30px;
	font-style: normal;
	font-weight: 600;
	line-height: 131.3%;
}

.circle-text {
	top: 70px;
	left: 40px;
}
.circle-img {
	top: 46px;
	left: 44px;
}

ul.markers-list {
	display: flex;
	list-style: none;
	max-width: 100%;
	min-width: 8rem;
	padding: 0;
	position: relative;
	width: 100%;
	z-index: 2;

	li {
		height: 1.375rem;// 22px
		margin: 0 0.5%;

		&:nth-child(1) {
		   margin-left: 0;
		   width: 21.37%;
	   }
		&:nth-child(2) {
		   width: 29.18%;
	   }
		&:nth-child(3) {
		   width: 15%;
	   }
		&:nth-child(4) {
		   width: 9.7%;
	   }
		&:nth-child(5) {
		   width: 9.5%;
	   }
		&:nth-child(6) {
		   width: 15.25%;
	   }
	}

}

.bg-theme-1 {
	// background-color: rgba(0, 151, 87, 1);
	background-color: #B8BCBF;
}
.bg-theme-2 {
	--tw-bg-opacity: 1;
	background-color: rgba(239, 125, 0, var(--tw-bg-opacity));
}
.bg-theme-3{
	--tw-bg-opacity: 1;
	background-color: rgba(230, 33, 90, var(--tw-bg-opacity));
}
.bg-theme-4{
	--tw-bg-opacity: 1;
	background-color: rgba(0, 79, 159, var(--tw-bg-opacity));
}
.bg-theme-5 {
	--tw-bg-opacity: 1;
	background-color: rgba(1, 134, 193, var(--tw-bg-opacity));
}
.bg-theme-6 {
	--tw-bg-opacity: 1;
	background-color: rgba(39, 174, 96, var(--tw-bg-opacity));
}
.bg-theme-7 {
	--tw-bg-opacity: 1;
	background-color: #22b5b7;
}
