.loader-container {
	width: 700px;
	height: 700px;
	position: relative;
}

.click-finger {
	position: absolute;
	top: 9rem;
	left: 13rem;
	z-index: 2;
}

.click-rounds {
	position: absolute;
	top: 15rem;
	left: 15rem;
	z-index: 1;
}