.content {
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	padding: 30px;

	@screen md {
		grid-template-columns: 1fr 1fr ;
	}
	@screen lg {
		grid-template-columns: 1fr 1fr 1fr;
	}
	@screen xl {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
	@screen 3xl {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		column-gap: 20px;
		row-gap: 40px;
	}
}

.project-card {
	img {
		width:100%;
		height: 16.1875rem;
		border-radius: 0.625rem;
		margin-bottom: 1rem;
		object-fit: cover;
		object-position: center;

		@screen md {
			width: 22.25rem;
		}
	}

	h2 {
		color: #131313;
		font-family: Rubik;
		font-size: 1.125rem;
		font-style: normal;
		font-weight: 400;
		line-height: 130%;
	}

	p {
		color: #77797A;
		font-family: Rubik;
		font-size: 1rem;
		font-style: normal;
		font-weight: 400;
		line-height: 130%;
	}
}

.images-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	flex-shrink: 0;

	@screen md {
		width: 40rem;
	}
}

.main-img {
	width: 100%;
	height: auto;
	margin-bottom: 1.5rem;
	border-radius: 1rem;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #CECDD2;

	@screen lg {
		width: 40rem;
		height: 30rem;
		}
}

.small-img {
	width: 0;
	height: auto;
	border-radius: 1rem;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #CECDD2;

	@screen lg {
		width: 12.625rem;
		height: 8.3125rem;
	}
}

.project-title {
	color: #000;
	font-family: Rubik;
	font-style: normal;
	font-weight: 400;
	font-size: 1.3rem;
	line-height: 1.3rem;
	margin-bottom: 1rem;

	@screen lg {
		font-size: 2.5rem;
		line-height: 2.5rem;
		min-height: 80px;
	}
}

.project-place {
	color: #77797A;
	font-family: Rubik;
	font-style: normal;
	font-weight: 400;
	font-size: 1.2rem;
	line-height: 1.3rem;
	margin-bottom: 1rem;

	@screen lg {
		font-size: 1.625rem;
		line-height: 2.5rem;
	}
}

.project-description {
	color: #000;
	font-family: Rubik;
	font-size: 1em;
	font-style: normal;
	font-weight: 400;
	line-height:1.3;
	margin-bottom: 1.5rem;

	@screen lg {
		font-size: 1.625rem;
		line-height: normal;
	}

	span {
		color: #77797A;
		font-family: Rubik;
		font-size: 1.625rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
}
