@tailwind base;
@tailwind components;
@tailwind utilities;

.app-wrapper {
  background-color: #fff;

  @screen 3xl {
    overflow-x: hidden;
    margin:auto;
  }
}

//-----классы для клавиатуры----
// .keyboard {
//   min-width: 800px !important;
//   width:100% !important;
// }
.hg-button {
	height: 50px !important;
}
.hg-button-bksp {
  background-color:  #bc213e !important;
  color:#fff!important;
}
.hg-button-enter {
  background-color: #2FADB3 !important;
  color: #fff !important;
}
.hg-theme-default .hg-button {
  font-size: 1.5rem;
}
