.window {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: none;
	background: rgba(0, 0, 0, 0.7);

	&.active {
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 5;
	}

	&__content--little {
		position: relative;
		background: #fff;
		padding: 20px;
		width: 31rem;
		border-radius: 1rem 1rem 0 0;
	}

	&__content--practices {
		position: relative;
		background: #fff;
		width: 90%;
		height: 100%;
		padding: 1rem;
		border-radius: 1rem;
		overflow-y: auto;

		@screen lg {
			padding: 3.75rem;
			height: 53rem;
		}
		@screen xl {
			width: 86rem;
			// border:1px solid red;
			overflow-y:inherit;
		}
	}

	&__content--projects {
		position: relative;
		background: #fff;
		width: 98rem;
		height: 46rem;
		padding: 2rem;
		border-radius: 1rem;
		overflow-y: auto;

			@screen xl
			{
				// border: 1px solid green;
				overflow-y: inherit;
			}
	}

	&__content--images {
		position: relative;
		background: #fff;
		// width: 73rem;
		// height: 54rem;
		border-radius: 1rem;

		@screen xl {
			width: 73rem;
			height: 54rem;
		}
	}

	&__content--keyboard {
		position: relative;
		background: #fff;
		width: fit-content;
		height: fit-content;
		padding: 1rem 2rem;
		border-radius: 1rem;
	}
}

.close-btn--big {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: #fff;
	// position: fixed;
	// right: 30px;
	width: 3rem;
	height: 3rem;
	top: 10px;

	position: absolute;
	right: 10px;

	@screen lg {
		position: fixed;
		width: 5.6875rem;
		height: 5.6875rem;
		top: 2rem;
		right: 3rem;
	}
}

.close-btn--little {
	display: flex;
	justify-content: flex-end;
}


.prev-btn {
	position: absolute;
	left: 1rem;
	top: 30%;
	width: 60px;
	height: 60px;
	// left: -7rem;
	// top: 50%;
	transform: translateY(-50%) rotate(180deg);

	@screen md {
		width:89px;
		height:89px;
		top: 50%;
	}

	@screen 3xl {
		left: -7rem !important;
		top: 50% !important;
	}
}

.next-btn {
	position: absolute;
	right: 1rem;
	top: 30%;
	width: 60px;
	height: 60px;
	// right: -7rem;
	// top: 50%;
	transform: translateY(-50%);

	@screen md {
		width: 89px;
		height: 89px;
		top: 50%;
	}

	@screen 3xl {
		right: -7rem !important;
		top: 50% !important;
	}
}

.bottom-strip {
	height: 0;
	// height: 16px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;

	&-projects {
		border-radius: 0 0 1rem 1rem;
	}

	@screen xl {
		height: 16px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

.image-circle {
	height: 1.5rem;
	width: 1.5rem;
	border-radius: 50%;
	background-color: #2FADB3;
	opacity: 0.5;
}

.modal-wrapper {
	width: 30rem;

	&>div {
		width: 21.5rem;
	}
}
